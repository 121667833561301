import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import slugify from "react-slugify";
import Img from "gatsby-image";
import Breadcrumb from "../components/breadcrumb.js";

const dictionary = require("../dic/dic.json");

class IntegrationTemplate extends Component {
  componentDidMount () {
    // delete  Te recomendamos
    const teRecomendamos = document.getElementsByClassName("rp4wp-related-posts");
    while (teRecomendamos.length > 0) {
      teRecomendamos[0].parentNode.removeChild(teRecomendamos[0]);
    }
    // Fin delete Te recomendamos
  }

  render () {
    const post = this.props.data.wpPost;
    const categories_integration = this.props.data.wpCategory.wpChildren.nodes;
    const main_slug = this.props.data.wpCategory.slug;
    // const slug= post.slug;
    const seo = post.seo;
    const langPage = this.props.pageContext.idioma;
    const alternates = post.translated;
    // const seoPage = page.seo
    let slugPage = post.uri;
    let openGraphslugPage = seo.opengraphUrl;
    slugPage = "/" + langPage + "/" + slugPage.substring(4, slugPage.length);
    openGraphslugPage = "/" + langPage + "/" + openGraphslugPage.substring(4, openGraphslugPage.length);
    alternates.forEach((alternate) => {
      const altLang = alternate.locale.locale.substring(0, 2);
      alternate.seo.canonical = "/" + altLang + "/" + alternate.uri.substring(4, alternate.uri.length);
    });
    if (
      slugPage.charAt(slugPage.length - 1) === "/"
    ) {
      slugPage = slugPage.substring(0, slugPage.length - 1);
    }

    // var slugPage = `${langPage}/${main_slug}/${slug}`;

    // slugPage=slugPage.substring(0,slugPage.length - 1);
    // var slugPage = seo.canonical

    // openGraphslugPage=openGraphslugPage.replace("https://getbillage.com/wp","");
    // openGraphslugPage=openGraphslugPage.replace("http://getbillage.com/wp","");
    // openGraphslugPage=openGraphslugPage.substring(0,openGraphslugPage.length -1);
    let class_div = "servicio-categoria";

    if (
      post.integraciones_proximamente !== null &&
      post.integraciones_proximamente.proximamenteIntegraciones === "Si"
    ) {
      class_div += " proximamente";
    }
    const breadcrumb = [];
    let categoria_principal = "Integraciones";
    let categoria_selected = "";
    let categoria_selected_slug = "";
    post.categories.nodes.forEach(categoria => {
      if (categoria.ancestors !== undefined && categoria.ancestors !== null) {
        categoria_principal = categoria.ancestors.nodes[0].name;
        categoria_selected = categoria.name;
        categoria_selected_slug = categoria.slug;
      }
    });
    let page = {
      title: categoria_principal,
      slug: "/" + langPage + "/" + main_slug
    };
    breadcrumb.push(page);
    page = {
      title: categoria_selected,
      slug: "/" + langPage + "/" + main_slug + "#" + categoria_selected_slug
    };
    breadcrumb.push(page);
    page = {
      title: post.title,
      slug: null
    };
    breadcrumb.push(page);
    return (
      <Layout currentPage={post.title} langPage={this.props.pageContext.idioma} alternates={alternates}>
        <SEO
          title={seo.title}
          description={seo.metaDesc}
          robotsNofollow={seo.metaRobotsNofollow}
          robotsNoindex={seo.metaRobotsNoindex}
          canonical={slugPage}
          openGraphSite={seo.opengraphSiteName}
          openGraphType={seo.opengraphType}
          openGraphUrl={openGraphslugPage}
          openGraphImg={
            seo.opengraphImage !== null ? seo.opengraphImage.sourceUrl : null
          }
          openGraphImgWidth={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.width
              : null
          }
          openGraphImgHeight={
            seo.opengraphImage !== null
              ? seo.opengraphImage.mediaDetails.height
              : null
          }
          dctermsCreated={post.date}
          dctermsModified={post.modified}
          lang={langPage.substring(0, 2)}
          twitterImg={
            seo.twitterImage !== null ? seo.twitterImage.sourceUrl : null
          }
          twitterImgHeight={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.height
              : null
          }
          twitterImgWidth={
            seo.twitterImage !== null
              ? seo.twitterImage.mediaDetails.width
              : null
          }
          alternates={alternates}
        />
        <Breadcrumb props={breadcrumb} isChild={"false"} isPage="true" />
        <div className="integraciones-ficha marketplace marketplace-detalle container">
          <div className="row">
            <div className="col-3 brc pt70">
              <ul className="categorias">
                <li className="titulo">{dictionary[langPage].categories}</li>
                <li className="filtros all">
                  <Link
                    to={`/${langPage}/${main_slug}#all`}
                  >
                    {dictionary[langPage].all}
                  </Link>
                </li>
                {categories_integration.map((category, i) => {
                  // const categoryRes=post.categories.nodes.find( categoryAux => categoryAux.name==category.name);
                  let active = "";
                  // if(categoryRes!=null){
                  //   active="active"
                  // }
                  const categoriesPost = post.categories.nodes;
                  for (const i in categoriesPost) {
                    if (categoriesPost[i].name === category.name) {
                      active = "active";
                    }
                  }

                  return (
                    <li key={"category_" + i} className="filtros">
                      <Link
                        to={`/${this.props.pageContext.idioma}/${main_slug}#${slugify(category.name)}`}
                        className={active}
                      >
                        {category.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12 col-md-3">
                  {post.featuredImage !== null &&
                    post.featuredImage.node !== null
                    ? (
                      <Img
                        fluid={
                          post.featuredImage.node.localFile.childImageSharp.fluid
                        }
                        alt={post.featuredImage.node.altText}
                      />
                    )
                    : null}
                </div>
                <div className="col-12 col-md-9">
                  {post.categories.nodes.map(category => (
                    <div>
                      {category.databaseId === 108 || category.databaseId === 7606 || category.databaseId === 117
                        ? null
                        : (
                          <div key={category.id} className={class_div}>
                            <div>{category.name}</div>
                          </div>
                        )}
                    </div>
                  ))}
                  <div className="servicio-contenido">
                    <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IntegrationTemplate;

export const pageQuery = graphql`
  query currentIntegrationQuery($id: String!, $categoryId : Int!) {
    wpPost(id: { eq: $id }) {
      title
      content
      date
      uri
      modified
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphPublisher
        opengraphImage {
          altText
          sourceUrl
          title
          mediaDetails {
            height
            width
          }
        }
        twitterDescription
        twitterTitle
        schema {
          pageType
        }
        twitterImage {
          altText
          mediaDetails {
            height
            width
          }
          sourceUrl
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          altText
          srcSet
          sourceUrl
          localFile {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      categories {
      nodes {
        name
        databaseId
        slug
        ancestors {
          nodes {
            name
            slug
          }
        }
      }
    }
      translated {
        slug
        locale{
          locale
        }
        seo {
          canonical
        }
        uri
      }
      integraciones_proximamente {
        proximamenteIntegraciones
      }
    }
    wpCategory(databaseId: { eq: $categoryId }) {
      wpChildren {
        nodes {
          id
          databaseId
          name
        }
      }
      slug
    }
  }
`;
